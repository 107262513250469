:root{
  --main-background: rgb(28, 27, 34);
  --main-foreground: #eee;
  --toolbar-border: #4a4a4b;
  --toolbar-box-shadow: black;
  --toolbar-button-hover: var(--grey-90-a30);
  --toolbar-button-active: var(--grey-90-a80);
  --popup-button-active: hsla(0,0%,70%,.6);
  --popup-bgcolor: rgb(66,65,77);
  --popup-button: #5c5c61;
  --popup-line: rgb(82, 82, 94);
  --selected-background: #3E6D9A;
  --link-foreground: #45a1ff;
  --visited-link-foreground: #e675fd;
  --link-selected-foreground: #fff;
  --opaque-popup-border: #434146;
  --font-value-border: #656468;
  --font-color: #fff;
  --icon-fill: #fff;
  --icon-disabled-fill: #ffffff66;
  --tooltip-background: black;
  --tooltip-foreground: white;
  --item-list-active-background: #3B3948;
}

body,
.modal-content {
  background-color: var(--main-background) !important;
  color: var(--main-foreground);
  font-family:  Helvetica, Arial, sans-serif; 
}

img {
  display: block;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: auto;
  background-color: var(--main-foreground);
}

code, pre {
  color: var(--main-foreground);
}

a, a:hover {
  color: var(--link-foreground);
}

.navbar{
  background-color: #181920;
}

.navbar-brand,.navbar-brand:focus, .navbar-brand:hover {
  color: #fff;
}
.navbar-nav .nav-link:focus, .navbar-nav .nav-link:hover {
  color: rgba(255,255,255,.75);
}
.navbar-nav .nav-link {
  color: rgba(255,255,255,.5);
}
.navbar-expand .navbar-nav .nav-link {
  padding-left: unset;
}
.nav-link {
  cursor: pointer;
}

.form-control {
  background-color: #343a40;
  border: 1px solid #343a40;
  border-radius: 0.1rem;
  color: var(--icon-disabled-fill);
}

.form-control:focus {
  color: #fff;
  background-color: #343a40;
  border: 2px solid #8b8e92;
  outline: 0;
  box-shadow: unset;
}

.list {
  text-align: left;
  margin: auto;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand p {
  margin-bottom: 0;
}

.logo {
  display: block;
  text-indent: -9999px;
  width: 50px;
  height: 50px;
  background: url(icons/logo.svg);
  background-size: 50px 50px;
}

.submit-form {
  max-width: 80%;
  margin: auto;
}

.article-list.list-group-item.active {
  background-color: var(--main-background);
  color: var(--main-foreground);
}

.article-list.list-group-item:first-child,
.article-list.list-group-item:last-child,
.article-list.list-group-item.active,
.article-list.list-group-item + .list-group-item.active,
.article-list.list-group-item + .list-group-item {
  margin-top: 0px;
  border: 1px solid var(--toolbar-border);
  border-radius: 6px;
  
}

.list-group-item {
  background-color: var(--main-background);
  border: 1px solid var(--toolbar-border);
  word-wrap:break-word;
}

.article-list.list-group-item {
  border-radius: 6px;
  margin-inline-start: max(calc(50% - 17px - var(--inline-padding)), calc(100% - 96px - 34px - 2 * var(--inline-padding)));
  list-style: none;
  user-select: none;
  margin-bottom: 20px;
}

.edit-form {
  max-width: 80%;
  margin: auto;
}

h4.article-item {
  cursor: pointer;
}
h4.article-item:hover {
  color: #fff;
}

.article-metadata {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 5px;
}

.article-metadata.inner {
  flex-wrap: wrap;
  align-self: flex-end;
  justify-content: flex-end;
  margin: 0;
  align-items: flex-end;
  max-width: 70%;
}

.article-metadata.inner .icons-list{
  margin-left: 15px;
}


.article hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(128, 128, 128);
  margin: 1em 0; 
  padding: 0;
}

.icons-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.icon {
  display: block;
  text-indent: -9999px;
  width: 25px;
  height: 25px;
  background-size: 25px 25px;
  margin-right: 5px;
  background-color: #343a40;
  cursor: pointer;
  mask-size: cover !important;
  -webkit-mask-size: cover !important;
}
.icon.true {
  background-color: #6f7022;
}
.icon:hover {
  background-color: var(--popup-button-active);
}
.icon.true:hover {
  background-color: #9fa11d;
}
.star {
  mask: url(icons/star.svg) no-repeat 50% 50%;
  -webkit-mask: url(icons/star.svg) no-repeat 50% 50%;
}
.archive {
  mask: url(icons/archive.svg) no-repeat 50% 50%;
  -webkit-mask: url(icons/archive.svg) no-repeat 50% 50%;
}
.tag-plus {
  mask: url(icons/tag-plus.svg) no-repeat 50% 50%;
  -webkit-mask: url(icons/tag-plus.svg) no-repeat 50% 50%;
}
.tag {
  mask: url(icons/tag.svg) no-repeat 50% 50%;
  -webkit-mask: url(icons/tag.svg) no-repeat 50% 50%;
}
.trash-can {
  mask: url(icons/trash-can.svg) no-repeat 50% 50%;
  -webkit-mask: url(icons/trash-can.svg) no-repeat 50% 50%;
}

.tag-list {
  display: flex;
  align-items: center;
  overflow: scroll;
  scrollbar-width: none;
  max-width: 50%;
}
.tag-list.warp {
  max-width: none;
  overflow: hidden;
  flex-wrap: wrap;
}
.tag-list::-webkit-scrollbar {
  display: none;
}
.tags-item {
  display: flex;
  align-items: center;
  margin-left: 7px;
  white-space: nowrap;
  color: var(--icon-disabled-fill);
  position: relative
}
.tags-item .tag {
  width: 19px;
  height: 19px;
  background-size: 19px 19px;
  margin-right: 1px;
  cursor: auto;
  background-color: var(--icon-disabled-fill);
}
.tags-item span{
  display: inline-block;
}
.tags-item .delete-tag {
  display: none;
}

.tags-item:hover .delete-tag,
.tags-item .delete-tag.active {
  display: inline-block !important;
  position: absolute;
  color: var(--icon-disabled-fill);
  right: -1px;
  text-align: center;
  cursor: pointer;
  border-radius: 15px;
  height: 23px;
  width: 23px;
  background: var(--popup-bgcolor);
  font-weight: bold;
  font-size: 17px;
}

.divisor-block{
  display: flex;
  align-items: center;
  color: #535455;
  justify-content: space-between;
}

.divider {
  display: inline-block;
  border-bottom: #535455 1px solid;
  width: 350px;
  margin: 30px;
}

.session-box {
  margin: auto;
  flex-grow: 5;
  flex-basis: 10%;
  margin-left: unset;
  margin-right: unset;
  min-height: 558.2px;
  display: flex;
}

.loading {
  margin: auto;
}

.login-options {
  display: flex;
  flex-direction: column;
}

.login-buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.login-buttons button{
  max-width: max-content;
}

.login-info-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.site-info {
  max-width: 35%;
  flex-shrink: 5;
  padding: 55px;
  border-right: #535455 1px solid;
}

.search-input {
  width: 45% !important;
  margin-left: auto;
}

@media (max-width: 767px)
{
  .site-info {
    max-width: none;
    border-bottom: #535455 1px solid;
    border-right: none;
    margin-bottom: 55px;
  }
  .session-box {
    margin-left: auto;
    margin-right: auto;
  }

  .search-input {
    width: 100% !important;
  }
}


.dropdown-menu  {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0,0,0,.15);
}

.dropdown-item {
  color: #dee2e6;
}

.dropdown-item:focus, .dropdown-item:hover {
  text-decoration: none;
  color: #fff;
  background-color: hsla(0,0%,100%,.15);
}

.dropdown-divider {
  border-color: rgba(0,0,0,.15);
}

.page {
  word-wrap: break-word;
}

.modal-autoheight .modal-body {
  min-height: 150px !important;
  max-height: 450px !important;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.modal-autoheight .modal-body .list-group {
  min-height: 100px !important;
}

.modal-autoheight .modal-body .list-group .list-group-item {
  display: flex;
  justify-content: space-between;
}

.modal-autoheight .modal-body .list-group .list-group-item:last-child {
  border-bottom: 1px solid var(--toolbar-border);
}

.modal-header {
  border-bottom: 1px solid var(--toolbar-border);
}

.modal-footer {
  border-top: 1px solid var(--toolbar-border);
}

.new-tags-form {
  border-bottom: 1px solid var(--toolbar-border);
  padding-bottom: 25px;
}

.new-tags-form .tag-list {
  min-height: 25px;
  margin-top: 8px;
}
.new-tags-form .submit-new-tags{
  margin-top: 15px;
}

.lds-facebook {
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #727F8C;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

@keyframes ldio-pazicmv58mg {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(2.0799999999999996,2.0799999999999996);
  } 100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}
.ldio-pazicmv58mg div > div {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #727f8c;
  animation: ldio-pazicmv58mg 1s linear infinite;
}.ldio-pazicmv58mg div:nth-child(1) > div {
  left: 71px;
  top: 45px;
  animation-delay: -0.875s;
}
.ldio-pazicmv58mg > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 76px 50px;
}.ldio-pazicmv58mg div:nth-child(2) > div {
  left: 63px;
  top: 63px;
  animation-delay: -0.75s;
}
.ldio-pazicmv58mg > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 68px 68px;
}.ldio-pazicmv58mg div:nth-child(3) > div {
  left: 45px;
  top: 71px;
  animation-delay: -0.625s;
}
.ldio-pazicmv58mg > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 50px 76px;
}.ldio-pazicmv58mg div:nth-child(4) > div {
  left: 27px;
  top: 63px;
  animation-delay: -0.5s;
}
.ldio-pazicmv58mg > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 32px 68px;
}.ldio-pazicmv58mg div:nth-child(5) > div {
  left: 19px;
  top: 45px;
  animation-delay: -0.375s;
}
.ldio-pazicmv58mg > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 24px 50px;
}.ldio-pazicmv58mg div:nth-child(6) > div {
  left: 27px;
  top: 27px;
  animation-delay: -0.25s;
}
.ldio-pazicmv58mg > div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 32px 32px;
}.ldio-pazicmv58mg div:nth-child(7) > div {
  left: 45px;
  top: 19px;
  animation-delay: -0.125s;
}
.ldio-pazicmv58mg > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 50px 24px;
}.ldio-pazicmv58mg div:nth-child(8) > div {
  left: 63px;
  top: 27px;
  animation-delay: 0s;
}
.ldio-pazicmv58mg > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 68px 32px;
}
.loadingio-spinner-spin-4ffxh03ubks {
  width: 30px;
  height: 25px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-pazicmv58mg {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.30);
  backface-visibility: hidden;
  transform-origin: -4px -4px;
}
.ldio-pazicmv58mg div { box-sizing: content-box; }
/* generated by https://loading.io/ */